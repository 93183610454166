<template>
  <div class="md-layout text-center">
    <div
      class="
        md-layout-item
        md-size-50
        md-medium-size-50
        md-small-size-60
        md-xsmall-size-60
      "
    >
      <img class="banner-img" src="@/assets/img/slide.jpg" alt="Globe Check" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style></style>
